<template>
    <div>
        <div class="address-settings__wrapper">
            <h1>{{ $t('user.addresses') }}</h1>
            <div class="btn__wrapper">
                <CRTag
                    class="tag"
                    v-for="address in user.addresses"
                    :key="address.id"
                    icon="location"
                    type="additional"
                    edit-allowed
                    svgColor="#00595A"
                    @edit="openEditModal(address)"
                >
                    {{ address.name }}
                </CRTag>
            </div>
            <h2 class="mb-16">{{ $t('user.createNewAddress') }}</h2>
            <AddressForm
                class="mt-30"
                is-edit-mode
                :address="selectedAddress"
                :address-errors="addressErrors"
                @onFormChange="onAddressFormChange"
            >
                <template #footer="{ address }">
                    <div class="btn__wrapper">
                        <CRButton class="btn" @click="saveAddress(address)">
                            {{ $t('buttons.saveNewAddress') }}
                        </CRButton>
                    </div>
                </template>
            </AddressForm>

            <EditAddressModal v-if="isEditAddressModalOpen" @close="closeEditModal" :address="editedAddress" />
        </div>
    </div>
</template>

<script>
    import CRTag from '@/components/Common/Tags/CRTag';
    import AddressForm from '@/components/Forms/AddressForm';
    import CRButton from '@/components/Common/Buttons/CRButton';
    import EditAddressModal from '@/components/Modals/EditAddressModal';
    import { INITIAL_ADDRESS_DATA } from '@/components/Forms/AddressForm.vue';
    import { CRUDTypeEnum } from '@/utils/enums';
    import { mapActions, mapGetters } from 'vuex';
    import { TYPE } from 'vue-toastification';
    import { cloneDeep } from 'lodash';
    import { useVuelidate } from '@vuelidate/core';

    export default {
        name: 'AddressesSettings',
        components: { AddressForm, CRTag, CRButton, EditAddressModal },
        setup() {
            return { v$: useVuelidate() };
        },
        data() {
            return {
                addressErrors: {},
                addressData: cloneDeep(INITIAL_ADDRESS_DATA),
                isEditAddressModalOpen: false,
                editedAddress: null,
                selectedAddress: null,
            };
        },
        computed: {
            ...mapGetters('auth', ['user']),
            ...mapGetters('city', ['selectedCity']),
        },
        methods: {
            ...mapActions('auth', ['destroyAddress', 'updateAddress', 'storeAddress']),

            onAddressFormChange(data) {
                this.addressData = data;
            },

            openEditModal(address) {
                this.selectedAddress = null;
                this.addressData = { ...INITIAL_ADDRESS_DATA };
                this.editedAddress = address;
                this.isEditAddressModalOpen = true;
            },

            closeEditModal() {
                this.editedAddress = null;
                this.addressData = { ...INITIAL_ADDRESS_DATA };
                this.isEditAddressModalOpen = false;
            },

            async saveAddress(address) {
                try {
                    const result = await this.v$.$validate();
                    if (!result) return;

                    let addressCopy = cloneDeep(address);

                    addressCopy.cityId = addressCopy.city.id;

                    delete addressCopy.city;

                    await this.storeAddress(addressCopy);

                    this.$filters.toast(addressCopy.name + this.$t(`otherSettings.${CRUDTypeEnum.CREATED}`), {
                        type: TYPE.SUCCESS,
                    });

                    this.addressData = cloneDeep(INITIAL_ADDRESS_DATA);
                    this.selectedAddress = cloneDeep(INITIAL_ADDRESS_DATA);

                    this.setInitialCity();
                } catch (error) {
                    this.addressErrors = error.errors;
                }
            },

            setInitialCity() {
                this.$nextTick(() => {
                    this.addressData.city = this.selectedCity;
                });
            },
        },
        mounted() {
            this.setInitialCity();
        },
    };
</script>

<style scoped lang="scss">
    .address-settings__wrapper {
        padding: 10px 0;

        max-width: 633px;

        .btn__wrapper {
            @include row-align-center;
            flex-wrap: wrap;

            margin-top: 15px;
            margin-bottom: 20px;

            .tag {
                margin: 0 0 8px 8px;
            }
        }
    }
</style>
